import styled from 'styled-components';
import ClientOnly from '../ClientOnly';

const Wrapper = styled.div`
  position: relative;

  label {
    font-weight: normal;
    font-size: var(--font-size-x-small);
    line-height: 1.2;
    color: var(--text-color);

    position: absolute;
    top: 0.5rem;
    left: var(--form-field-padding-x);
    margin: 0;

    pointer-events: none;

    opacity: 0;
    transform: translateY(0.5rem);
    transition: all 0.25s 0s var(--easing);
  }

  input {
    height: 56px;
    appearance: none;
    background-color: var(--form-field-bg);
    border: 1px solid var(--form-field-border-color);
    border-radius: 0;
    color: var(--text-color);
    display: inline-block;
    font-family: var(--body-font-family);
    font-size: 0.9375rem;

    line-height: 1.5;
    margin: 0;
    max-width: 100%;
    outline: 0;
    padding: var(--form-field-padding-y) var(--form-field-padding-x);
    text-align: left;
    text-decoration: none;
    text-transform: none;
    width: 100%;

    transition: all 0.25s 0s var(--easing);

    &:focus {
      border-color: var(--form-field-focus-border-color);
      background-color: var(--form-field-focus-bg);
    }

    &:not(:placeholder-shown) {
      padding-bottom: 0.5rem;
      padding-top: calc(var(--form-field-padding-y) * 2 - 0.5rem);

      + label {
        opacity: 0.5;
        transform: translateY(0);
      }
    }
  }
`;

const InputFallback = styled.div`
  height: 56px;
  width: 100%;
  background-color: var(--form-field-bg);
`;

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  label: string;

  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

const Input = ({ label, onChange, ...rest }: Props) => (
  <Wrapper>
    <input {...rest} id={label} placeholder={label} onChange={onChange} autoComplete="off" />
    <label htmlFor={label}>{label}</label>
  </Wrapper>
);

const ClientOnlyInput = ({ label, onChange, ...rest }: Props) => (
  <ClientOnly fallBack={<InputFallback />}>
    <Input label={label} onChange={onChange} {...rest} />
  </ClientOnly>
);

export default Input;
export { ClientOnlyInput };
